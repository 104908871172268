import React from 'react'
import Component from './index.js'
import useRemark from 'hooks/useRemark'

export default function EditableTransformer({ data, ...props }) {
  const address = useRemark(data?.address)
  const cookieDisclaimerText = useRemark(data?.cookieDisclaimerText)
  return (
    <Component data={{ ...data, address, cookieDisclaimerText }} {...props} />
  )
}
